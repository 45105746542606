import React, { useState } from "react"
import { View, Text } from "react-native"
import range from "./utils/range"

import { Picker } from "@react-native-picker/picker"

/**
 *       <NumberPickerView
        style={{ flex: 3, backgroundColor: "green" }}
        id={1}
        label="reps"
        min={0}
        max={100}
        initialValue={9}
      />
 * 
 * @param {*} pickerProps 
 * @returns 
 */
const NumberPickerView = (pickerProps) => {
  const {
    id,
    label = "",
    min,
    max,
    disabled = false,
    initialValue,
  } = pickerProps
  const [selectedLanguage, setSelectedLanguage] = useState(initialValue)

  const numbers = range(min, max)

  return (
    <View style={{ alignItems: "center" }}>
      <Text>asshole</Text>
      <Picker
        selectedValue={selectedLanguage}
        onValueChange={(itemValue, itemIndex) => setSelectedLanguage(itemValue)}
        style={{ width: 200 }}
      >
        {numbers.map((number, index) => (
          <Picker.Item
            key={`${id}-${number}-${index}`}
            value={number}
            label={`${number} ${label}`}
            enabled={!disabled}
          />
        ))}
      </Picker>
    </View>
  )
}

export default NumberPickerView
