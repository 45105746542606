import * as React from "react"
import { Button, View, Text } from "react-native"
import { NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import MyCarousel from "./app/MyCarousel"
import NumberPickerView from "./app/NumberPickerView"

function HomeScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Text>home Screen</Text>
      <MyCarousel props={{ data: [1, 2, 3, 69] }} />
      <Button
        title="Go to Home bucko"
        onPress={() => navigation.push("Details")}
      />
    </View>
  )
}

function DetailsScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Text>Details Screen</Text>
      <NumberPickerView
        id={1}
        label="reps"
        min={12}
        max={100}
        initialValue={9}
      />
      <Button
        title="Go to Home bucko"
        onPress={() => navigation.push("Home")}
      />
    </View>
  )
}

const Stack = createNativeStackNavigator()

const linking = {
  prefixes: ["https://app.gotrainr.com"],
  config: {
    screens: {
      Home: {
        path: "homeurl",
      },
      Details: "detailsurl",
    },
  },
}

function App() {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          initialParams={{ data: [1, 2, 3, 69] }}
        />
        <Stack.Screen name="Details" component={DetailsScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default App
