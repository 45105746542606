import * as React from "react"
import { Dimensions, Text, View, TouchableOpacity } from "react-native"
import Swiper from "react-native-web-swiper"
import NumberPickerView from "./NumberPickerView"

/**
 *
 * @param {*} props props.route.params will contain info that this component was initted with
 * @returns
 */
function MyCarousel(props) {
  const width = Dimensions.get("window").width
  const height = Dimensions.get("window").height - 300
  //   const { data } = props.route.params

  //   console.log(props.route.params)
  //   console.log(data)
  console.log(width)
  return (
    <View style={{ width: width, height: height }}>
      <Text>lmao</Text>
      <Swiper
        from={1}
        minDistanceForAction={0.1}
        controlsProps={{
          dotsTouchable: true,
          prevPos: "left",
          nextPos: "right",
          nextTitle: ">",
          nextTitleStyle: {
            color: "white",
            fontSize: 24,
            fontWeight: "500",
            // backgroundColor: "red",
          },
          PrevComponent: ({ onPress }) => (
            <TouchableOpacity onPress={onPress}>
              <Text style={{ color: "white", fontSize: 24, fontWeight: "500" }}>
                {"<"}
              </Text>
            </TouchableOpacity>
          ),
        }}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(20,20,200,0.3)",
          }}
        >
          <Text>Slide 1</Text>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(20,200,20,0.3)",
          }}
        >
          <Text>Slide 2</Text>
          <NumberPickerView
            style={{ flex: 3, backgroundColor: "green" }}
            id={1}
            label="reps"
            min={0}
            max={100}
            initialValue={9}
          />
        </View>
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Text>Slide 3</Text>
        </View>
      </Swiper>
    </View>
  )
}

export default MyCarousel
